const state = {
    headerActiveId: 1,
    isMobile: true,
    isShowLoginModal: false,
    schoolConfiInfo: {}
}

const mutations = {
    CHANGE_HEADERACTIVEID: (state, id) => {
        state.headerActiveId = id
    },
    CHANGE_ISMOBILE: (state, bool) => {
        state.isMobile = bool
    },
    CHANGE_ISSHOWLOGINMODAL: (state, bool) => {
        state.isShowLoginModal = bool
    },
    SETSCHOOLINFO: (state, info) => {
        state.schoolConfiInfo = info
    }
}

const actions = {
    changeHeaderActiveId({ commit }, data) {
        commit('CHANGE_HEADERACTIVEID', data)
    },
    changeIsMobile({ commit }, bool) {
        commit('CHANGE_ISMOBILE', bool)
    },
    changeIsShowLoginModal({ commit }, bool) {
        commit('CHANGE_ISSHOWLOGINMODAL', bool)
    },
    setSchoolInfo({ commit }, info) {
        commit('SETSCHOOLINFO', info)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}