<template>
  <div id="header" class="boxC">
    <div class="header boxS">
      <div class="left box">
        <div id="logo" class="boxC" @click="onNavbarChange({ id: 1, url: '/' })">
          <img :src="logoImg" alt="" />
        </div>
        <div class="searchBox boxC">
          <el-input
            :placeholder="$t('public.placeholderText')"
            v-model="searchValue"
            class="input-with-select"
            @change="onSearchChange"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              :style="'background-color:' + schoolConfigInfo.colors || '#a60002'"
              @click="onSearchChange"
            ></el-button>
          </el-input>
        </div>
      </div>
      <div class="userBox box">
        <div class="navbar box" v-if="isLogin">
          <div class="navbar_item box">
            <router-link :to="{ path: 'my', query: { tabId: 1 } }">{{
              $t("header.myClassText")
            }}</router-link>
          </div>
          <div class="navbar_item box">
            <router-link :to="{ path: 'my', query: { tabId: 2 } }">{{
              $t("header.myExamText")
            }}</router-link>
          </div>
          <div class="navbar_item box">
            <router-link :to="{ path: 'my', query: { tabId: 4 } }">{{
              $t("header.settingText")
            }}</router-link>
          </div>
        </div>
        <div class="no_login box" v-if="!isLogin" @click="onGoLogin">
          {{ $t("header.loginBtnText") }}/{{ $t("header.registerBtnText") }}
        </div>
        <div class="user_avatar boxC" v-if="!isLogin">
          <img
            :src="userInfo.avatarPath ? userInfo.avatarPath : defaultAvatar"
            alt=""
            width="100%"
            height="100%"
            style="border-radius: 50%"
          />
        </div>
        <el-dropdown v-if="isLogin">
          <div class="user_avatar boxC">
            <img
              :src="userInfo.avatarPath ? userInfo.avatarPath : defaultAvatar"
              alt=""
              width="100%"
              height="100%"
              style="border-radius: 50%"
            />
          </div>
          <el-dropdown-menu slot="dropdown">
            <span @click="onLogout">
              <el-dropdown-item>退出登录</el-dropdown-item>
            </span>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import LogoImg from "@/assets/common/logo.png";
import DefaultAvatar from "@/assets/common/avatar.png";
export default {
  name: "Header",
  data() {
    return {
      logoImg: LogoImg,
      defaultAvatar: DefaultAvatar,
      searchValue: "",
      userInfo: {},
    };
  },
  computed: {
    navbarId() {
      return this.$store.getters.headerActiveId;
    },
    isLogin() {
      return sessionStorage.getItem("token") ? true : false;
    },
    schoolConfigInfo() {
      return this.$store.getters.schoolConfigInfo;
    },
  },
  mounted() {
    if (sessionStorage.getItem("userInfo")) {
      this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    }
  },
  methods: {
    // 搜索
    onSearchChange() {
      if (this.$route.name == "List") {
        let newUrl = this.changeURLArg(
          window.location.href,
          "searchValue",
          this.searchValue
        );
        window.location.replace(newUrl);
      } else {
        this.$router.push({
          path: "list",
          query: { searchValue: this.searchValue, ...this.$route.query },
        });
      }
    },
    onLogout() {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("userInfo");
      this.$router.replace({ path: "/" });
      window.location.reload();
    },
    changeURLArg(url, arg, arg_val) {
      var pattern = arg + "=([^&]*)";
      var replaceText = arg + "=" + arg_val;
      if (url.match(pattern)) {
        var tmp = "/(" + arg + "=)([^&]*)/gi";
        tmp = url.replace(eval(tmp), replaceText);
        return tmp;
      } else {
        if (url.match("[\?]")) {
          return url + "&" + replaceText;
        } else {
          return url + "?" + replaceText;
        }
      }
      return url + "\n" + arg + "\n" + arg_val;
    },
    // 去登录
    onGoLogin() {
      // this.isLogin = true;
      this.$store.dispatch("public/changeIsShowLoginModal", true).then((res) => {
        console.log("isCloseLoginModalSuccess");
      });
    },
    onNavbarChange(item) {
      this.$store.dispatch("public/changeHeaderActiveId", item.id).then((res) => {
        this.$router.push({ path: item.url });
        window.scrollTo(0, 0);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#header {
  width: 100%;
  height: 76px;
  position: fixed;
  top: 0;
  background: #fff;
  z-index: 99;
  box-shadow: 0px 2px 4px 0px #dddddd;
  .header {
    width: 1200px;
    height: 100%;
    .left {
      #logo {
        height: 100%;
        cursor: pointer;
        img {
          width: auto;
          height: 48px;
        }
      }
      .searchBox {
        width: 592px;
        margin-left: 62px;
      }
    }
    .userBox {
      .navbar {
        height: 40px;
        .navbar_item {
          height: 100%;
          margin-right: 16px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          cursor: pointer;
        }
      }
      .no_login {
        height: 40px;
        margin-right: 16px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        cursor: pointer;
      }
      .user_avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }
  }
}
</style>
<style scoped>
.left >>> .el-input-group__append {
  width: 80px;
  height: 40px;
  background: #a60002;
  color: #fff;
}
.left >>> .el-input-group__append .el-button {
  width: 80px;
  height: 40px;
  font-size: 18px;
}
.searchBox >>> .el-input__inner {
  width: 100%;
  height: 40px;
  line-height: 40px;
}
</style>
